import { render, staticRenderFns } from "./AtividadesHistoricosTempos.vue?vue&type=template&id=06d46aac&scoped=true"
import script from "./AtividadesHistoricosTempos.vue?vue&type=script&lang=js"
export * from "./AtividadesHistoricosTempos.vue?vue&type=script&lang=js"
import style0 from "./AtividadesHistoricosTempos.vue?vue&type=style&index=0&id=06d46aac&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06d46aac",
  null
  
)

export default component.exports